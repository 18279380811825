import { TextItems } from "../components/Common/Paragraph";

export const LIST_ITEMS: TextItems[] = [
  {
    id: 1,
    text: "Melhor controle das entradas e saídas;",
  },
  {
    id: 2,
    text: "Informação onde estão seus maiores gastos e como reduzir tudo que for possível;",
  },
  {
    id: 3,
    text: "Saber para para onde o dinheiro está indo;",
  },
  {
    id: 4,
    text: "Análise financeira sobre seus custos e despesas;",
  },
  {
    id: 5,
    text: "Identificação de gargalos financeiros;",
  },
  {
    id: 6,
    text: "Redução de custos desnecessários, sem perder a qualidade do seu serviço ou produto.",
  },
];

export const PARAGRAPHS_CONSULTING: TextItems[] = [
  {
    id: 1,
    text: `Somos uma empresa de consultoria especializada em gestão
    empresarial/financeira. Nosso propósito é promover troca de
    conhecimentos e experiências proporcionando a entrega do melhor
    resultado aos nossos clientes.`,
  },
  {
    id: 2,
    text: `Como escritório contábil (Rubi Contabilidade) atuamos desde meados de
    1996 até 05/2022, sempre buscando a excelência no atendimento ao
    cliente, oferecendo soluções práticas e objetivas.`,
  },
  {
    id: 3,
    text: `Durante todo esse período, nossa maior propaganda foi os nossos próprios
    clientes, que após avaliarem o resultado dos trabalhos realizados, nos
    encaminham cada vez mais clientes e amigos.`,
  },
  {
    id: 4,
    text: `Buscamos sempre desenvolver e implantar soluções moldando-as às
    especificidades de cada caso.`,
  },
  {
    id: 5,
    text: `Acreditamos na inovação e no compartilhamento de ideias. Apostamos na
    estratégia e em um planejamento moderno baseado em mudanças.`,
  },
  {
    id: 6,
    text: `A <strong>consultoria em gestão financeira</strong> é baseada nas
    informações sobre a ordenação atual da organização com coleta de dados
    recolhendo informações suficientes para a futura análise.`,
  },
  {
    id: 7,
    text: `Então, a consultoria fornecerá à empresa uma proposta de modelo
    financeiro e orçamentário, elaborado especificamente às necessidades e
    objetivos da organização.`,
  },
];

export const PARAGRAPHS_MEI: TextItems[] = [
  {
    id: 1,
    text: `Administrar corretamente o próprio patrimônio não é uma tarefa 
    fácil, principalmente caso o MEI esteja crescendo. Com o crescimento 
    do negócio, é fundamental manter o controle sobre as próprias atividades 
    financeiras para não comprometer a empresa. Por isso, o MEI deve contar 
    com um profissional para auxiliá-lo nas mais diversas situações que se 
    apresentam.`,
  },
  {
    id: 2,
    text: `O consultor poderá contribuir com conhecimentos técnicos para 
    que o microempreendedor possa administrar melhor os próprios recursos. 
    Assim, ao crescer com sua empresa, estará seguro no cumprimento das 
    normas e procedimentos financeiros.`,
  },
  {
    id: 3,
    text: `Nessa parceria, o consultor pode esclarecer dúvidas, indicar os 
    melhores caminhos e ajudá-lo a crescer de maneira significativa.`,
  },
  {
    id: 4,
    text: `Nossa consultoria/assessoria irá proporcionar mais agilidade e 
    segurança e ainda permitir que o MEI foque o trabalho em si, tendo a 
    certeza de que a tarefa será desempenhada da melhor forma possível.`,
  },
];

export const PARAGRAPHS_HOLDING: TextItems[] = [
  {
    id: 1,
    text: `Um dos grandes problemas que se apresentam, principalmente para os
    grupos familiares, é o inerente à sucessão hereditária no que se refere
    à continuidade dos negócios e proteção do patrimônio.`,
  },
  {
    id: 2,
    text: `A dificuldade que normalmente surge é a decorrente da herança recebido
    por filho ou filha e esses bens, numa segunda etapa, também por
    falecimento ou dissolução da sociedade conjugal, passam a pertencer a
    uma pessoa estranha à família, os chamados “agregados”, muitas vezes
    despreparada para dar continuidade ao mesmo estilo administrativo.`,
  },
  {
    id: 3,
    text: `Atualmente, no Brasil, a regra para transmitir o patrimônio da pessoa
    falecida aos herdeiros é por meio do inventário.`,
  },
  {
    id: 4,
    text: `O problema é que o inventário é um procedimento caro, burocrático,
    extremamente demorado, não promove conciliação familiar e pode ser
    imensamente traumático. Além disso, o patrimônio fica exposto à
    deterioração e eventuais dívidas.`,
  },
  {
    id: 5,
    text: `E é nesse cenário de caos que a Holding Familiar se apresenta como uma
    excelente ferramenta para proteger o patrimônio, organizar a sucessão e,
    não menos importante, economizar impostos.`,
  },
  {
    id: 6,
    text: `A constituição de uma Holding pode ser usada para o controle e sucessão
    da administração dos negócios da família e/ou para a proteção
    patrimonial (administradora de imóveis próprios), com redução na carga
    tributária (IR – ITBI - ITCMD) e facilitando o processo de inventário.`,
  },
  {
    id: 7,
    text: `É um planejamento sucessório seguro, vantajoso e inteligente.`,
  },
];

export const PARAGRAPHS_TAX: TextItems[] = [
  {
    id: 1,
    text: `Cuidamos com muito carinho e profissionalismo da sua declaração de
    Imposto de Renda, tratando-a como uma verdadeira contabilidade
    pessoal, administrando-a de forma legal dentro da legislação em vigor,
    com o objetivo de evitar pagamento indevido de imposto e problemas com
    a malha fina da Receita Federal.`,
  },
  {
    id: 2,
    text: `Você será atendido individualmente com todo o sigilo que o assunto
    requer, sendo os serviços realizados por profissional qualificado, com
    mais de 30 anos de experiência no assunto.`,
  },
];

export const PARAGRAPHS_CAPITAL: TextItems[] = [
  {
    id: 1,
    text: `No caso de venda de imóveis de seu patrimônio pessoal, realizamos a
    Apuração de Ganho de Capital (Lucro Imobiliário) com muito critério e
    antes da efetivação da transação de venda, de modo que você possa
    planejar os detalhes da transação.`,
  },
  {
    id: 2,
    text: `Calculamos o Ganho de Capital de acordo com as normas oficiais da 
    Receita Federal de modo que você pague apenas o imposto que,
    realmente, seria devido.`,
  },
  {
    id: 3,
    text: `Quando necessário, realizamos revisão de suas declarações de IRPF dos
    últimos 05 (cinco) anos.`,
  },
];

export const PARAGRAPHS_HOME: TextItems[] = [
  {
    id: 1,
    text: `A Rubi Consultoria é uma empresa que atua em Itu e região, prestando
    consultoria de gestão patrimonial, especializada na
    constituição de holding familiar e em Imposto de Renda Pessoa
    Física. Transparência, confiabilidade e profissionalismo são nossos
    princípios básicos para uma parceria de sucesso.`,
  },
  {
    id: 2,
    text: `Atender nossos clientes sempre com profissionalismo, seriedade e
    credibilidade, superando suas expectativas e contribuindo para seu
    sucesso.`,
  },
  {
    id: 3,
    text: `O reconhecimento como empresa referência em consultoria
    patrimonial, com uma gestão moderna e eficaz.`,
  },
];