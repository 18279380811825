import { Box, Flex, Icon, Image, Link, List, ListItem } from "@chakra-ui/react";
import { AiFillPhone } from "react-icons/ai";
import { MdOutlinePhoneIphone } from "react-icons/md";
import logo from "../assets/logo_round.png";
import headerBg from "../assets/bg_header_new.jpeg";
import useMediaQueries from "../helpers/MediaQueries";

const Header = () => {
  const { isLarge, isMedium, isMobile } = useMediaQueries();

  return (
    <Box
      flexShrink="0"
      display="flex"
      flexDirection="column"
      p="0.4rem"
      fontSize="0.8rem"
      color="white"
      justifyContent="center"
      bgImage={headerBg}
      bgPosition="top"
      bgRepeat="repeat-x"
      h={isLarge ? "180px" : isMedium ? "120px" : "60px"}
    >
      <Box
        display="flex"
        fontFamily="Trebuchet MS"
        fontSize="1rem"
        textTransform="uppercase"
        textAlign="center"
        fontWeight="600"
      >
        <Flex zIndex={1} ms="1rem">
          <Link href="/" textDecoration="none">
            <Image
              src={logo}
              alt="logo Rubi"
              mt={"2rem"}
              w={isLarge ? "12rem" : isMedium ? "8rem" : "4rem"}
            />
          </Link>
        </Flex>
        {!isMobile && (
          <Flex alignItems="center" ms={isLarge ? "3rem" : "1rem"}>
            <List display="inline-flex" gap={isLarge ? "8" : "4"}>
              <ListItem>
                <Link
                  href="/"
                  _hover={{ textDecoration: "none", color: "white" }}
                >
                  Nossa empresa
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href="/servicos"
                  _hover={{ textDecoration: "none", color: "white" }}
                >
                  Nossos serviços
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href="/contato"
                  _hover={{ textDecoration: "none", color: "white" }}
                >
                  Contato
                </Link>
              </ListItem>
            </List>
          </Flex>
        )}
        {isLarge ? (
          <Flex
            flexDirection="column"
            justifyContent="center"
            ms="auto"
            me="1rem"
          >
            <Link
              href="tel:011996605249"
              target="_blank"
              rel="noopener noreferrer"
              textDecoration="none"
              _hover={{ textDecoration: "none", color: "white" }}
            >
              <Icon
                as={MdOutlinePhoneIphone}
                color="white"
                w="20px"
                h="20px"
                me="0.2rem"
              />
              (11)99660-5249
            </Link>
          </Flex>
        ) : null}
      </Box>
    </Box>
  );
};

export default Header;
