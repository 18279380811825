import { Box, Image, Text } from "@chakra-ui/react";
import oops from "../assets/oops.png";

const NotFound = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Text textAlign="center">Hmmm. Essa página parece não existir.</Text>
      <Image src={oops} alt="Not Found" w="40%" />
    </Box>
  );
};

export default NotFound;
