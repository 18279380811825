import { Box, Heading, Image } from "@chakra-ui/react";
import RubiParagraph, { TextItems } from "./Paragraph";

interface SectionBoxProps {
  image: string;
  title: string;
  paragraphs: TextItems[];
  children?: any;
}

const SectionBox = (props: SectionBoxProps) => {
  const { image, paragraphs, title, children } = props;

  return (
    <Box>
      <Heading mt="2rem" textDecoration={"underline"}>
        {title}
      </Heading>
      <Image mt="1rem" src={image} />
      {paragraphs.map((paragraph) => (
        <RubiParagraph key={paragraph.id} text={paragraph.text} />
      ))}
      {children}
    </Box>
  );
};

export default SectionBox;
