import { Routes, Route } from "react-router-dom";
import NotFound from "./NotFound";
import Contact from "./Contact";
import Services from "./Services";
import Home from "./Home";

const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/servicos" element={<Services />} />
      <Route path="/contato" element={<Contact />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default AllRoutes;
