import { useMediaQuery } from "@chakra-ui/react";

const useMediaQueries = () => {
  const [isLarge] = useMediaQuery("(min-width: 850px)");
  const [isMedium] = useMediaQuery("(max-width: 849px)");
  const [isMobile] = useMediaQuery("(max-width: 630px)");
  return { isLarge, isMedium, isMobile };
};

export default useMediaQueries;
