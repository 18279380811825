import { Box, Flex, Link, List, ListItem } from "@chakra-ui/react";
import sidebarBg from "../assets/bgSidebar.jpeg";
import useMediaQueries from "../helpers/MediaQueries";

interface SidebarProps {
  isMenuOpen: boolean;
}

const Sidebar = (props: SidebarProps) => {
  const { isMenuOpen } = props;
  const { isMedium } = useMediaQueries();

  return (
    <>
      {isMenuOpen && isMedium && (
        <Box
          flexShrink="0"
          display="flex"
          flexDirection="column"
          p="0.4rem"
          fontSize="1.2rem"
          color="white"
          justifyContent="center"
          bgImage={sidebarBg}
          bgRepeat="repeat-y"
          bgPosition={"right"}
          w="auto"
          fontFamily="Trebuchet MS"
          textTransform="uppercase"
          textAlign="center"
          fontWeight="600"
          position={"absolute"}
          top="120px"
          right="0"
        >
          <Flex flexDir={"column"} gap="8" pb="5" mx="0.25rem" mt="1rem">
            <List display="flex" flexDir={"column"} textAlign="center" gap="8">
              <ListItem>
                <Link
                  href="/"
                  _hover={{ textDecoration: "none", color: "white" }}
                >
                  Nossa empresa
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href="/servicos"
                  _hover={{ textDecoration: "none", color: "white" }}
                >
                  Nossos serviços
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href="/contato"
                  _hover={{ textDecoration: "none", color: "white" }}
                >
                  Contato
                </Link>
              </ListItem>
            </List>
          </Flex>
        </Box>
      )}
    </>
  );
};

export default Sidebar;
