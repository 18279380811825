import {
  Box,
  Container,
  Icon,
  Link,
  Text,
  VStack,
  Image,
  Heading,
} from "@chakra-ui/react";
import {
  FaFacebook,
  FaLinkedin,
  FaRegEnvelope,
  FaWhatsapp,
} from "react-icons/fa";
import instagram from "../assets/instagram.png";
import useMediaQueries from "../helpers/MediaQueries";
import ContactForm from "./ContactForm";

const Contact = () => {
  document.title = "Contato | Rubi Consultoria";
  const { isMobile } = useMediaQueries();
  return (
    <Container fontWeight={600}>
      <VStack display={"flex"} justifyContent="center">
        <Heading fontSize={"4xl"} mt={"3rem"} fontFamily="Montserrat">
          Entre em contato
        </Heading>
        <Text pt={"2rem"} textAlign={"center"}>
          Entre em contato por uma das seguintes formas e retornaremos sua
          mensagem com brevidade.
        </Text>
        <Box
          display={"flex"}
          w={isMobile ? "70%" : "60%"}
          py="3rem"
          justifyContent={"space-between"}
        >
          <Box>
            <Link
              href="mailto:adm@rubiconsultoria.net.br"
              target="_blank"
              _hover={{ color: "black" }}
              rel="noopener noreferrer"
            >
              <Icon as={FaRegEnvelope} w="48px" h="48px" />
            </Link>
          </Box>
          <Box>
            <Link
              href="https://www.facebook.com/RubiConsultoriadeItu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon
                color="#3B5998"
                as={FaFacebook}
                aria-hidden="true"
                w="48px"
                h="48px"
              />
            </Link>
          </Box>
          <Box>
            <Link
              href="https://wa.me/5511996605249"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon
                color="#1EBEA5"
                as={FaWhatsapp}
                aria-hidden="true"
                w="42px"
                h="48px"
              />
            </Link>
          </Box>
          <Box>
            <Link
              href="https://www.linkedin.com/company/rubi-consultoria-itu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon
                color="#0077B5"
                as={FaLinkedin}
                aria-hidden="true"
                w="42px"
                h="48px"
              />
            </Link>
          </Box>
          <Box>
            <Link
              href="https://www.instagram.com/rubiconsultoriadeitu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image src={instagram} w="48px" h="48px" />
            </Link>
          </Box>
        </Box>
        <Text pt={"2rem"} textAlign={"center"}>
          Se preferir, preencha o formulário abaixo e entraremos em contato o
          mais breve possível.
        </Text>
        <ContactForm />
      </VStack>
    </Container>
  );
};

export default Contact;
