import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Textarea,
} from "@chakra-ui/react";
import { useForm } from "@formspree/react";

const ContactForm = () => {
  const [state, handleSubmit] = useForm("xoqzqbrd");

  if (state.succeeded) {
    return (
      <Box color="#e51e24" pt="2rem">
        Formulário enviado com sucesso!
      </Box>
    );
  }

  return (
    <>
      <Box h="1.5rem" />
      <Box
        p={8}
        w="80%"
        borderWidth={1}
        borderRadius={8}
        boxShadow="lg"
        mt="2rem"
      >
        <form onSubmit={handleSubmit}>
          <FormControl isRequired mt="1rem">
            <FormLabel>Nome:</FormLabel>
            <InputGroup>
              <Input type={"text"} placeholder="Seu nome" name="name" />
            </InputGroup>
          </FormControl>
          <FormControl isRequired mt="1rem">
            <FormLabel>Email:</FormLabel>
            <InputGroup>
              <Input
                type={"text"}
                placeholder="seu@email.com.br"
                name="email"
              />
            </InputGroup>
          </FormControl>
          <FormControl isRequired mt="1rem">
            <FormLabel>Telefone:</FormLabel>
            <InputGroup>
              <Input type={"text"} placeholder="Seu telefone" name="phone" />
            </InputGroup>
          </FormControl>
          <FormControl isRequired mt="1rem">
            <FormLabel>Mensagem:</FormLabel>
            <InputGroup>
              <Textarea placeholder="Sua mensagem" name="message" />
            </InputGroup>
          </FormControl>

          <Center>
            <Button
              type="submit"
              disabled={state.submitting}
              mt="2rem"
              bgColor="#e51e24"
              color="white"
            >
              Enviar
            </Button>
          </Center>
        </form>
      </Box>
      <Box h="2rem" />
    </>
  );
};

export default ContactForm;
