import { Box, Flex, Icon, Image, Link, Text } from "@chakra-ui/react";
import { FaEnvelope } from "react-icons/fa";
import maps from "../assets/maps.png";
import bgFooter from "../assets/bg_header_new.jpeg";
import { AiFillPhone } from "react-icons/ai";
import useMediaQueries from "../helpers/MediaQueries";
import { MdOutlinePhoneIphone } from "react-icons/md";

const Footer = () => {
  const { isMobile } = useMediaQueries();
  return (
    <Flex
      flexDirection={"column"}
      flexShrink={0}
      padding="0.4rem"
      color="white"
      bgImg={bgFooter}
      bgPos="bottom"
      bgRepeat="repeat-x"
      fontWeight={"semibold"}
    >
      <Flex
        flexDirection={isMobile ? "column" : "row"}
        justifyContent={"space-around"}
        alignItems="center"
        fontFamily="Montserrat"
        textTransform={"uppercase"}
        mb="0.25rem"
      >
        <Link
          href="tel:011996605249"
          target="_blank"
          rel="noopener noreferrer"
          textDecoration="none"
          _hover={{ textDecoration: "none", color: "white" }}
        >
          <Icon
            as={MdOutlinePhoneIphone}
            color="white"
            w="20px"
            h="20px"
            me="0.2rem"
          />
          (11)99660-5249
        </Link>
        <Flex justifyContent={"center"}>
          <Link
            href="mailto:adm@rubiconsultoria.net.br"
            target="_blank"
            rel="noopener noreferrer"
            _hover={{ textDecoration: "none", color: "white" }}
          >
            <Flex alignItems={"center"} justifyContent={"center"}>
              <Icon
                aria-hidden="true"
                as={FaEnvelope}
                className="fas fa-envelope"
              />
              <Text ms="1">adm@rubiconsultoria.net.br</Text>
            </Flex>
          </Link>
        </Flex>
      </Flex>
      <Box textAlign={"center"} fontSize="small" fontWeight={600}>
        <Text mt="0.5rem">© Rubi Consultoria 2024</Text>
      </Box>
    </Flex>
  );
};

export default Footer;
