import { Box } from "@chakra-ui/react";
import useMediaQueries from "../helpers/MediaQueries";
import family from "../assets/images/family.jpeg";
import tax from "../assets/images/tax.jpeg";
import bill from "../assets/images/bill.jpg";
import SectionBox from "./Common/SectionBox";
import { PARAGRAPHS_HOLDING, PARAGRAPHS_TAX, PARAGRAPHS_CAPITAL } from "../text/Text";

const Services = () => {
  document.title = "Você | Rubi Consultoria";
  const { isMobile } = useMediaQueries();
  return (
    <Box w={isMobile ? "90%" : "50%"} mx="auto" pb="2rem">
      <SectionBox
        image={family}
        paragraphs={PARAGRAPHS_HOLDING}
        title="Holding Familiar e Proteção Patrimonial:"
      />
      <SectionBox
        image={tax}
        paragraphs={PARAGRAPHS_TAX}
        title="Imposto de Renda Pessoa Física:"
      />
      <SectionBox
        image={bill}
        paragraphs={PARAGRAPHS_CAPITAL}
        title="Ganhos de Capital (Lucro Imobiliário):"
      />
    </Box>
  );
};

export default Services;
