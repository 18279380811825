import {Text} from "@chakra-ui/react";

export interface TextItems {
  id: number,
  text: string
}

interface RubiParagraphProps {
  text: string;
}

const RubiParagraph = (props: RubiParagraphProps) => {
  const { text } = props;
  return <Text pt="1rem" dangerouslySetInnerHTML={{__html: text}}/>;
};

export default RubiParagraph;