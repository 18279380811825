import { Box, Flex, Heading, Image } from "@chakra-ui/react";
import useMediaQueries from "../helpers/MediaQueries";
import business from "../assets/images/business.jpeg";
import mission from "../assets/images/mission.jpeg";
import vision from "../assets/images/vision.jpeg";
import RubiParagraph from "./Common/Paragraph";
import { PARAGRAPHS_HOME } from "../text/Text";

const Home = () => {
  document.title = "Início | Rubi Consultoria"
  const { isLarge, isMedium, isMobile } = useMediaQueries();
  return (
    <Box w={isLarge ? "55%" : isMobile ? "90%" : "70%"} mx="auto" pb="2rem">
      <Flex
        mt="3rem"
        gap={5}
        alignItems="center"
        flexDirection={isMedium ? "column" : "row"}
      >
        {isLarge && <Image w="40%" src={business} />}
        <Box w={isMedium ? "80%" : undefined}>
          <Heading textDecoration={"underline"}>A Empresa:</Heading>
          {isMedium && (
            <Flex mt={3} justifyContent={"center"}>
              <Image w="100%" src={business} />
            </Flex>
          )}
          <RubiParagraph text={PARAGRAPHS_HOME[0].text}
          />
        </Box>
      </Flex>
      <Flex
        mt="2rem"
        gap={5}
        alignItems="center"
        flexDirection={isMedium ? "column-reverse" : "row"}
      >
        <Box w={isMedium ? "80%" : undefined}>
          <Heading textDecoration={"underline"}>Nossa Missão:</Heading>
          {isMedium && (
            <Flex mt={3} justifyContent={"center"}>
              <Image w="100%" src={mission} />
            </Flex>
          )}
          <RubiParagraph text={PARAGRAPHS_HOME[1].text}/>
        </Box>
        {isLarge && <Image w="40%" src={mission} />}
      </Flex>
      <Flex
        mt="2rem"
        gap={5}
        alignItems="center"
        flexDirection={isMedium ? "column" : "row"}
      >
        {isLarge && <Image w="40%" src={vision} />}
        <Box w={isMedium ? "80%" : undefined}>
          <Heading textDecoration={"underline"}>Nossa Visão:</Heading>
          {isMedium && (
            <Flex mt={3} justifyContent={"center"}>
              <Image w="100%" src={vision} />
            </Flex>
          )}
          <RubiParagraph text={PARAGRAPHS_HOME[2].text}/>
        </Box>
      </Flex>
    </Box>
  );
};

export default Home;
