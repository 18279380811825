import { ChakraProvider, IconButton } from "@chakra-ui/react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import "./App.css";
import Sidebar from "./components/Sidebar";
import { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import useMediaQueries from "./helpers/MediaQueries";
import { BrowserRouter } from "react-router-dom";
import AllRoutes from "./components/AllRoutes";

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const { isMobile } = useMediaQueries();
  return (
    <BrowserRouter>
      <ChakraProvider>
        <div className="App">
          <Header />
          <Sidebar isMenuOpen={isMenuOpen} />
          <main className="appMain">
            {isMobile && (
              <IconButton
                as={GiHamburgerMenu}
                w="50px"
                h="50px"
                mt="2"
                ms="2"
                position={"absolute"}
                top="1.25rem"
                right="1rem"
                aria-label={"toggle menu"}
                bgColor="transparent"
                color={"white"}
                _hover={{ bgColor: "transparent" }}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                cursor="pointer"
              />
            )}
            <AllRoutes />
          </main>
          <Footer />
        </div>
      </ChakraProvider>
    </BrowserRouter>
  );
};

export default App;
